import React, { useState, useEffect, useRef} from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";

import {Navbar} from '../components/Navbar'
import {Footer} from '../components/Footer'
import {NavAsistencia} from '../components/NavAsistencia'

import {Template} from '../components/template'
import {NuevaAsistencia} from '../components/NuevaAsistencia'

const URL_ASISTENCIAS = 'https://api-asistencias.educacioncba.edu.ar/'
const URL_CONTACTOS = 'https://api-contactos.educacioncba.edu.ar'


export const CasoNuevo = () => {

  
  const isAuthenticated = useIsAuthenticated();
  //console.log(isAuthenticated)

 

  const user =  JSON.parse(sessionStorage.getItem('usuario'));  
  //console.log(user)

  const [escuelaCaso, setEscuelaCaso] = useState({});
  const [cargaContacto, setCargaContacto] = useState(false);
  const [contactos, setContactos] = useState({});
  const [contacto, setContacto] = useState({});
  const [infoContacto, setInfoContacto] = useState(false);
  const [nuevocontacto, setNuevoContacto] = useState(false);
  const [nuevocaso, setnuevocaso] = useState(false);
  const [telCNuevo, setTelCNuevo] = useState(false);
  const [correoAlternativo, setCorreoAlternativo] = useState('');
 

  const id_escuela = sessionStorage.getItem('idEscuela') 


const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ] 
}

const [inputRef, setInputFocus] = useFocus()
  const obtenerDatos = async () =>{


    //console.log(id_escuela)

    if(id_escuela != null){
        const data = await fetch('https://escuelas.educacioncba.edu.ar/api/escuela/id/'+id_escuela)
        const resultado = await data.json()
      
        //console.log(resultado.data[0])

        setEscuelaCaso(resultado.data)
        cargarEscuela(resultado.data)


        
        const dataUsers = await fetch(URL_CONTACTOS+'/escuela/'+id_escuela+'/contactos') 
        const users = await dataUsers.json()
 
        mostrarContacto(users)
        
        //console.log(users)
        
      }else{

       window.location.href = window.location.origin;
       //console.log(window.location.origin) 
       //console.log('no se puede trabajar asi')
      }
    

  }
  

    useEffect(() => {
          obtenerDatos()
    }, [])


  const cargarEscuela = (escuelaCaso) =>{

    //console.log(escuelaCaso)
 
    let infoEscuela = document.getElementById('infoEscuela')
    
    let lista = document.createElement('list')
    lista.setAttribute('className', 'list-group list-group-flush')
  

    escuelaCaso.forEach(establecimiento => {
  
      console.log(establecimiento)

      let nombre = establecimiento.cue.nombre
      
      let contNombre = document.createElement('div')
      contNombre.setAttribute('className', 'd-flex flex-row')
     
      let hNombre = document.createElement('h5')
      hNombre.setAttribute('className', 'mx-2 mt-3')
      hNombre.innerHTML = nombre
  
      contNombre.appendChild(hNombre)

      let cue = document.createElement('p')
      cue.setAttribute('className', 'mx-3 mb-2 mt-3')
      cue.innerHTML = '<strong> CUE: </strong>'+establecimiento.cue.cue +' <strong className="mx-2"> EE: </strong>'+establecimiento.codigo_empresa

      let nivel = document.createElement('p')
      nivel.setAttribute('className', 'mx-3 mb-2')
      nivel.innerHTML = '<strong> Nivel: </strong>'+establecimiento.nivel.nombre

    

      let direccion = document.createElement('p')
      direccion.setAttribute('className', 'mx-3 mb-2')
      direccion.innerHTML = '<strong> Dirección: </strong>' +
      (establecimiento.ubicacion?.direccion || '') + ' - ' +
      (establecimiento.ubicacion?.localidad?.nombre || '') + ' - ' +
      (establecimiento.ubicacion?.departamento?.nombre || '');
      
      lista.appendChild(contNombre)
      lista.appendChild(cue)
      lista.appendChild(nivel)
      lista.appendChild(direccion)
      
      
    });

    infoEscuela.appendChild(lista)

  }

  const mostrarContacto = async(contactos) =>{

    
    setContactos(contactos)
    setCargaContacto(true)

    let selectUsuario = document.getElementById('selectUsuario')

    let texto = document.createElement('h3')
    texto.setAttribute('className','enunciado-gral')
    texto.innerHTML = 'Cargando...'


    let rowOp = document.createElement('div')
    rowOp.setAttribute('className', 'row d-flex justify-content-center')
    
    let contOp = document.createElement('div')
    contOp.setAttribute('className', 'col-5 d-flex justify-content-center')

    let spinner = document.createElement('div')
    spinner.setAttribute('className', 'spinner-border text-secondary')
    spinner.setAttribute('role', 'status')

    let spanSpinner = document.createElement('span')
    spanSpinner.setAttribute('className', 'visually-hidden')
    spanSpinner.innerHTML = 'Cargando...'

    spinner.appendChild(spanSpinner)
    contOp.appendChild(spinner)
    rowOp.appendChild(contOp)

 
    let rowInput = document.createElement('div')
    rowInput.setAttribute('className', 'row d-flex justify-content-center')

    let contInput = document.createElement('div')
    contInput.setAttribute('className', 'col-5 mb-3 ')


    let op_default = document.createElement('option')
    op_default.setAttribute('id', 0 )
    op_default.innerHTML = 'Seleccione una opción' 

    selectUsuario.appendChild(op_default)

    contactos.data.forEach(contacto => {
        
        if(contacto.nombre_persona.includes('prueba') || contacto.nombre_persona.includes('PRUEBA') ||contacto.nombre_persona.includes('Prueba') || contacto.nombre_persona.includes('Nombre *patrimonial*') || contacto.nombre_persona == ''){
          //console.log(contacto.id)
          //console.log(contacto.nombre_persona)

        }else{
        
          let op = document.createElement('option')
          op.setAttribute('id', contacto.id )
        
          op.innerHTML = contacto.nombre_persona

          selectUsuario.appendChild(op)
               
        }

      })
    
      let op_nuevo = document.createElement('option')
      op_nuevo.setAttribute('id', 'nuevo')
      op_nuevo.innerHTML = 'Nuevo contacto' 

      selectUsuario.appendChild(op_nuevo)

      
      //texto.innerHTML =' accion.titulo'

      //contInput.appendChild(select)    
      //rowInput.appendChild(contInput)

      //selectUsuario.appendChild(rowInput)  
      
    }

  const obtenerContacto = (e) => {

    const index = e.target.selectedIndex;
    const selectedId = e.target.options[index].id;
    
    let contFooter = document.getElementById('contFooter')
    contFooter.scrollIntoView({ behavior: 'smooth' });

    if (selectedId === 'nuevo') {

      setContacto('');
      setInfoContacto(false);
      setNuevoContacto(true);

    } else if (selectedId === '0') {
      setContacto('');
      setNuevoContacto(false);
    } else {

          setNuevoContacto(false);


          const contact = contactos.data.find(contact => contact.id === parseInt(selectedId));
          

          if (contact) {
            setInfoContacto(true);
            setContacto(contact);
            setInputFocus()
            
          } else {
            console.log('Contacto no encontrado =(');
          }
    }
    }

  const esTelefonoValido = (telefono) => {
      // Ejemplo de expresión regular para un formato de teléfono (adaptar según necesidades)
      const regex = /^(\d{10,13}|\d{5}-\d{5,8})$/;
      return regex.test(telefono);
    }

  const [correoErroneo, setCorreoErroneo] = useState('')

  const correosInvalidos = [
    'a@a.com',
    'a@correo.com',
    'correo@a.com',
    'a@prueba.com',
    'prueba@a.com',
    'correo@correo.com',
    'mail@mail.com',
    'mail@correo.com',
    'otro@correo.com',
    'correo@prueba.com',
    'prueba@prueba.com',
    'prueba@correo.com',
    'ejemplo@@dominio.com', 
    'correoinvalido', 
    '@dominio.com',
    'ejemplo@dominio', 
    'correo espaciado@dominio.com',
    'ejemplo@dominio .com' 
    ];

  const esEmailValido = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    if (correosInvalidos.includes(email)) {
      setCorreoErroneo(email)
      return false; // Si el correo está en la lista de inválidos, retorna false
    }

    return regex.test(email);
    }

  const comprobarContacto = () =>{
    
    let esValido = true;
    let emailNuevo = document.getElementById('txt-mailAlt');
    

    if (emailNuevo.value === '' || !esEmailValido(emailNuevo.value)) {
      emailNuevo.classList.add('is-invalid');
      esValido = false;
    } else {
      emailNuevo.classList.remove('is-invalid');
      emailNuevo.classList.add('is-valid');
      setInfoContacto(false)
      setnuevocaso(true) 
      let contFooter = document.getElementById('contFooter')
      contFooter.scrollIntoView({ behavior: 'smooth' });
    }

    }

  const actualizarCorreo = () =>{
    setCorreoAlternativo('')
    }

  const [telValido, setTelValido] = useState(true);
  const [telNuevo, setTelNuevo] = useState('');
  const [cargoSeleccionado, setCargoSeleccionado] = useState('');

  // Lista de cargos disponibles
  const cargos = [
    'Ayudante técnico',
    'Profesor/a',
    'Preceptor/a',
    'Coordinador/a',
    'Secretaria/o',
    'Vicedirector/a',
    'Director/a',
    'Administrador de red'
  ];

  // Función para manejar el cambio de selección
  const handleChange = (e) => {
    setCargoSeleccionado(e.target.value);
  };

  const nuevoContacto = async () =>{
   
    let nombreNuevo = document.getElementById('nombreNuevo');
    let cargoNuevo = document.getElementById('cargoNuevo');
 
    let esValido = true; // Variable para rastrear si todos los campos son válidos
    
    if (nombreNuevo.value === '') {
      nombreNuevo.classList.add('is-invalid');
      esValido = false; // Si este campo no es válido, marcar como no válido
    } else {
      nombreNuevo.classList.remove('is-invalid');
      nombreNuevo.classList.add('is-valid');
    }
    
    if (cargoNuevo.value === '') {
      cargoNuevo.classList.add('is-invalid');
      esValido = false; // Si este campo no es válido, marcar como no válido
    } else {
      cargoNuevo.classList.remove('is-invalid');
      cargoNuevo.classList.add('is-valid');
    }
    
    // Verificación del formato del teléfono
    if (telNuevo === '' || !esTelefonoValido(telNuevo)) {
         
      setTelCNuevo(true)
      setTelValido(false)
      esValido = false; // Si este campo no es válido, marcar como no válido

    } else {
      
      setTelValido(true)
      setTelCNuevo(false)
    }
    // Si todos los campos son válidos, mostrar un console.log
    if (esValido) {

      console.log('Todos los campos son válidos');

      let url = URL_CONTACTOS+'/escuela/'+id_escuela+'/contactos';
      

      await fetch(url , {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            tipo : 'Teléfono celular',
            ambito : 'Persona',
            nombre_persona : nombreNuevo.value,
            cargo : cargoNuevo.value,
            contacto : telNuevo
            }
        ),
      })
      .then(response => response.json())
      .then(response => 
      {
        setNuevoContacto(false)
        setContacto(response.data)
        setInputFocus()
        setInfoContacto(true)
      }
      )
      .catch(err => console.error(err));
  
    }
  
  }



   const actualizarProceso = () =>{

    setNuevoContacto(false)
    setInfoContacto(false)
    setContacto('')

    var selectContactos = document.getElementById('selectUsuario');
    selectContactos.selectedIndex = 0;

   }


  return (
    <div>
    <Navbar/>
    <div className="container">
     
      <NavAsistencia/>
       
        <div className="card mt-4">
            <div className="card-header">
                <div className="row">
                    <div className="col-12 col-lg-12">
                         <div className="hstack gap-1">
                         <span className="material-symbols-outlined lg">
                            assignment_add
                          </span>                 
                            <span>
                                Nueva asistencia
                            </span>
                        </div>   
                    </div>
                </div>
            </div>
            <div className="card-body" >
              
                  <div id="contNuevoCaso">

                  <div className='col-12 mt-2'>
                            <div className="card">
                                <div className="card-header bg-transparent">
                                  <div className="hstack gap-1">
                                      <span className="material-symbols-outlined celeste">
                                        school
                                      </span>
                                      Escuela
                                  </div>   
                                </div>
                                <div className="card-body">
                                    { !escuelaCaso ? 
                                          <div className="text-center">
                                            <h6 className="text-secondary mt-3">Cargando información...</h6>
                                            <div className="spinner-border text-secondary" role="status">
                                                  <span className="visually-hidden">Loading...</span>
                                            </div> 
                                          </div>  
                                          : ''
    
                                    }  
                                  <div id="infoEscuela"> 

                                  </div>  
                                </div>
                            </div>
                        </div>


                        <div className= 'col-12 mt-3' >
                            <div className="card">
                                <div className="card-header bg-transparent">
                                  <div className="hstack gap-1">
                                      <span className="material-symbols-outlined celeste">
                                      settings_account_box
                                      </span>
                                      Información de contacto
                                  </div>   
                                </div>

                                
                                  <div className={cargaContacto == false ? "text-center mt-3 mb-3" : 'd-none'}>

                                    <h6 className="text-secondary mt-3">Cargando información...</h6>
                                    <div className="spinner-border text-secondary" role="status">
                                          <span className="visually-hidden">Loading...</span>
                                    </div> 
                                  </div>  
                                  
                                  <div className= {cargaContacto == true ? 'card-body' :'d-none'}>

                                    <div className="input-group mt-2">
                                        <span className="input-group-text" id="basic-addon3">Responsable</span>
                                        <select 
                                          className="form-select text-secondary" 
                                          id="selectUsuario"
                                          onChange={obtenerContacto}
                                        >
                                        </select>
                                    </div>
                                              
                                    <div className={contacto.id == 'nuevo' || contacto.id == '-' ? 'd-none' : 'mt-3'}>
                                      
                                        <p className='mx-2 mt-2'><strong className= {contacto.nombre_persona ? '': 'd-none'}> Nombre:   </strong> {contacto.nombre_persona}</p>
                                        <p className='mx-2'><strong className= {contacto.cargo ? '': 'd-none'}> Cargo: </strong> {contacto.cargo}</p>
                                        <p className='mx-2'><strong className= {contacto.contacto ? '': 'd-none'}> Teléfono: </strong> {contacto.contacto}</p>
                                  
                                    </div>                                                                

                                    <div className={nuevocontacto == true ? ' mx-3 mt-3' : 'd-none'}>
                                            <div className="input-group mb-3">
                                              <span className="input-group-text" >Apellido y Nombre</span>
                                              <input type="text" className="form-control" id="nombreNuevo" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                                            </div>   

                                            <div className="input-group mb-3">
                                              <span className="input-group-text">Cargo</span>
                                              <select
                                                className="form-select text-secondary"
                                                id="cargoNuevo"
                                                aria-label="Seleccionar cargo"
                                                value={cargoSeleccionado}
                                                onChange={handleChange}
                                              >
                                                <option value="">Seleccione cargo</option>
                                                {cargos.map((cargo, index) => (
                                                  <option key={index} value={cargo}>
                                                    {cargo}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>    

                                            <div className="input-group mb-3">
                                              <span className="input-group-text">Teléfono</span>
                                              <input
                                                type="text"
                                                id="telNuevo"
                                                className={`form-control ${telValido ? '' : 'is-invalid'}`}
                                                value={telNuevo}
                                                onChange={(e) => setTelNuevo(e.target.value)}
                                                aria-label="Sizing example input" 
                                                aria-describedby="inputGroup-sizing-default"
                                              />
                                              {telCNuevo == true && (
                                                <div className="invalid-feedback">
                                                  Por favor, ingrese un número telefónico valido.
                                                </div>
                                              )}
                                            </div>

                                            
                                            <div className="input-group mb-3 d-flex justify-content-end">
                                            <button 
                                              type="button" 
                                              className="btn btn-outline-success"
                                              onClick={nuevoContacto}>
                                                Guardar
                                            </button>
                                        </div>
                                    </div>


                                    <div className="input-group mt-2">
                                            <span className="input-group-text" id="basic-addon2">Correo alternativo</span>
                                            <input
                                                type="text"
                                                id="txt-mailAlt"
                                                className='form-control'
                                                ref={inputRef}
                                                value={correoAlternativo}
                                                onChange={(e) => setCorreoAlternativo(e.target.value)}
                                               
                                                aria-label="Sizing example input" 
                                                aria-describedby="inputGroup-sizing-default"
                                              />
                                           
                                            {!nuevocaso == true && (
                                                <div className="invalid-feedback">
                                                  Por favor, ingrese un correo electrónico válido.
                                                  <br/>{correoErroneo ? '*'+correoErroneo : ''}
                                                </div>
                                              )}
                                      </div>

                                  </div>
                                        
                            </div>
                        </div>
               
                       <div className={infoContacto == true ? 'col-12 mt-4' : 'd-none'}  >
                           <div className= 'card  callout-info' >
                           
                                 <div className="card-body" id="bodyAyuda">
                                    <div className='row'>
                                        <h5 className="card-title hstack gap-1"><span className="material-symbols-outlined">help</span><div id="tituloAyuda">Información de contacto</div></h5>
                                        <p className="card-text" id="cuerpoAyuda">Ingrese el correo electrónico alternativo y presione continuar.</p>
                                    </div>
                                <div className='row'>
                                        <div className= "d-grid gap-2 d-md-flex justify-content-md-center mt-3" >
                                            <button className="btn btn-outline-danger me-md-2" type="button" onClick={actualizarProceso}>Cancelar</button>
                                            <button className="btn btn-outline-secondary" type="button" id="btnComprobarContacto" onClick={comprobarContacto}>Continuar</button>

                                        </div>
                                </div>    
                            </div>

                           </div>
                        </div>
                   

                    
                        <div className={nuevocaso == true ? 'col-12  mt-3' : 'd-none'}>
                            <div className="card">
                                <div className="card-header ">
                                  <div className="hstack gap-1">
                                      <span className="material-symbols-outlined celeste">
                                      assignment_late
                                      </span>
                                      Detalle del inconveniente
                                  </div>   
                                </div>
                                <div className="card-body " id="contNuevaAsistencia">
                                    <NuevaAsistencia contacto={contacto} escuela={escuelaCaso}/>
                                </div>

                            </div>

                        </div>

                       


                                    
                </div>


                </div>
               
                    <div className="card-footer text-muted" >
                
            </div>
        </div>
     </div>
       <Footer/>
</div> 
  )


}
