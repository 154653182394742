import React, { useState, useEffect} from 'react'
import {Template} from './template'
import {dataCategoria} from './DataCategoria'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";



const URL_ASISTENCIAS = 'https://api-asistencias.educacioncba.edu.ar/'

const id_escuela = sessionStorage.getItem('idEscuela') 


export const NuevaAsistencia = (contacto) => {

    const isAuthenticated = useIsAuthenticated();
    const [User, setUser] = useState({});
    const { instance, accounts, inProgress } = useMsal();

    const [escuelaCaso, setEscuelaCaso] = useState({});
    const [checkboxState, setCheckboxState] = useState([false, false, false, false]);

    const [checkboxStateSub, setcheckboxStateSub] = useState(
        new Array(dataCategoria.length).fill(false)
    );

    const [tituloAyuda, settituloAyuda] = useState(false);
    const [cuerpoAyuda, setCuerpoAyuda] = useState(false);
    const [catInternet, setCatInternet] = useState(false);
    const [catAsistencia, setCatAsistencia] = useState(false);
    const [catCorreo, setCatCorreo] = useState(false);
    const [catTelefonia, setCatTelefonia] = useState(false);

    const [openInternet, setOpenInternet] = useState(false)
    const [openAsistencia, setOpenAsistencia] = useState(false)
    const [openCorreo, setOpenCorreo] = useState(false)
    const [openTelefonia, setOpenTelefonia] = useState(false)

    const [casosInternet, setCasosInternet] = useState([])
    const [casosAsistencia, setCasosAsistencia] = useState([])
    const [casosCorreo, setCasosCorreo] = useState([])
    const [casosTelefonia, setCasosTelefonia] = useState([])


    const [intExist, setIntExist] = useState(true);
    const [correoExist, setCorreoExist] = useState(true);
    const [asistenciaExist, setAsistenciaExist] = useState(true);
    const [telExist, setTelExist] = useState(true);


    const [mostraExtra, setMostraExtra] = useState(false);

    const [informacionExtra, setinformacionExtra] = useState(false);
    const [btnInfo, setbtnInfo] = useState(false);
    const [btnExtra, setbtnExtra] = useState(false);
    const [btnCorreo, setbtnCorreo] = useState(false);
    const [checkGuardar, setcheckGuardar] = useState(false);

    const [cantEquipos, setcantEquipos] = useState(1);
    const [cantUsuarios, setcantUsuarios] = useState(1);

    const [datosFiltrados, setdatosFiltrados] = useState([]);


    const [detalleInternet, setDetalleInternet] = useState('');
    const [detalleAsistencia, setDetalleAsistencia] = useState('');
    const [detalleCorreo, setDetalleCorreo] = useState('');
    const [detalleTelefonia, setDetalleTelefonia] = useState('');

    const [isCheckedReclamo,  setIsCheckedReclamo] = useState(false);
    const [isCheckedServicio, setIsCheckedServicio] = useState(false);
    const [isCheckedEmpresa,  setIsCheckedEmpresa] = useState(false);
    const [valorNroReclamo, setValorNroReclamo] = useState('');
    const [valorNroServicio, setNroServicio] = useState('');

    const [inputs, setInputs] = useState([]);  
    const [personas, setPersonas] = useState([]); 

    const maxCaracteres = 150;

    const [casoInternet, setCasoInternet] = useState('')
    const [casoAsistencia, setCasoAsistencia] = useState('')
    const [casoCorreo, setCasoCorreo] = useState('')
    const [casoTelefonia, setCasoTelefonia] = useState('')
    
    const [controlGuardar, setControlGuardar] = useState(false)

    const [mostrarInternet, setMostrarInternet ] = useState(false)           
    const [mostrarAsistencia, setMostraraAsistencia] = useState(false) 
    const [mostrarCorreo, setMostrarCorreo] = useState(false) 
    const [mostrarTelefonia, setMostrarTelefonia] = useState(false) 

    useEffect(() => {
        if(isAuthenticated === true){
            setUser(accounts)
            //console.log(User)
        }
   })
    useEffect(() => {
        obtenerDatos()
      }, [])

    const obtenerDatos = async () =>{

        const id_escuela = sessionStorage.getItem('idEscuela') 
        const user = JSON.parse(sessionStorage.getItem('usuario'));  
    
        const data = await fetch('https://escuelas.educacioncba.edu.ar/api/escuela/id/'+id_escuela)
        const resultado = await data.json()
      

        //console.log(resultado.data[0])
    
        setEscuelaCaso(resultado.data)
        //cargarEscuela(resultado.data)

        
        const asistencias = await fetch(URL_ASISTENCIAS+'tickets?id_escuela='+id_escuela) //1747  
        const ticket = await asistencias.json()
        
        //console.log(ticket.data)
        cargarAyuda('Categorías', 'Seleccione la opción que corresponde a su pedido.');

        ticket.data.forEach(element => {
            
            if(element.estado === 'nuevo' || element.estado === 'abierto' || element.estado === 'asignado'){

                //console.log(element.nro_ticket)

                if(element.titulo.split('-')[1].trim() === 'SOPORTE TÉCNICO'){
                    setOpenAsistencia(true)
                    setCasosAsistencia([...casosAsistencia, element.nro_ticket.toString()]);
                }
                if(element.titulo.split('-')[1].trim() === 'SOLICITUD DE DESBLOQUEO'){
                    setOpenAsistencia(true)
                    setCasosAsistencia(prevCasosInternet => [
                        ...prevCasosInternet,
                        element.nro_ticket.toString()
                      ]);
                }
                if(element.titulo.split('-')[1].trim() === 'CORREO INSTITUCIONAL'){
                    setOpenCorreo(true)
                    setCasosCorreo(prevCasosInternet => [
                        ...prevCasosInternet,
                        element.nro_ticket.toString()
                      ]);
                }
                if(element.titulo.split('-')[1].trim() === 'TELEFONÍA'){
                    setOpenTelefonia(true)
                    setCasosTelefonia(prevCasosInternet => [
                        ...prevCasosInternet,
                        element.nro_ticket.toString()
                      ]);
                }
                if(element.titulo.split('-')[1].trim()  === 'INTERNET'){
                    setOpenInternet(true)
                    //console.log(element.nro_ticket)
                    setCasosInternet(prevCasosInternet => [
                        ...prevCasosInternet,
                        element.nro_ticket.toString()
                      ]);
                }

                //console.log(element.nro_ticket)
                //console.log(element.servicio)
                //console.log(element.estado)
                //console.log(element.titulo.split('-')[1].trim()) 
            }

            //140311000
            // nuevo abierto asignado

            // Soporte técnico
            // Solicitud de desbloqueo
            // Correo Institucional
            // Telefonía

        });
        
    }
    
    const selCategoria = (e) =>{
        
        let categoria = e.target.id
        let estado = e.target.checked

        if(checkboxState){

        const updatedCheckedState = checkboxState.map((item, index) =>
            index === parseInt(e.target.name) ? !item : item
        );

        setCheckboxState(updatedCheckedState);  
        }
        
        
        cargarAyuda('Subcategoría', 'Para estimar el caso seleccione las subcategorías que correspondan a su caso y presione Continuar.') 

        switch (categoria) {
            case 'internet':
                setCatInternet(estado)
                break;
            case 'asistencia':
                setCatAsistencia(estado)
                break;
            case 'correo':
                setCatCorreo(estado)
                break;
            case 'telefonia':
                setCatTelefonia(estado)
                break;
            default:

                break;
        }
     }

    const handleRadioChange = (e) => {
        const radioId = parseInt(e.target.id);
        const isChecked = e.target.checked;
    
        // Actualizar estado de los radio buttons
        const updatedCheckedState = checkboxStateSub.map((item, index) =>
          index === radioId - 1 ? isChecked : false
        );
        setcheckboxStateSub(updatedCheckedState);
    
        // Actualizar datos filtrados según la selección del radio button
        if (isChecked) {
          const resultInternet = dataCategoria.filter(item => item.id === radioId);
          setdatosFiltrados(resultInternet);
        } else {
          const resultInternet = datosFiltrados.filter(item => item.id !== radioId);
          setdatosFiltrados(resultInternet);
        }
    
        // Actualizar estados de existencia basados en la selección de radio buttons
        const anyChecked = updatedCheckedState.some(item => item === true);
        setAsistenciaExist(anyChecked);
        setCorreoExist(anyChecked);
        setIntExist(anyChecked);
        setTelExist(anyChecked);
    };

    const controlChange = (e) => {


          if(checkboxStateSub){
            
            const updatedCheckedState = checkboxStateSub.map((item, index) =>
                index === parseInt(e.target.id-1) ? !item : item
            );
            setcheckboxStateSub(updatedCheckedState);  
            }
        
            
        if(e.target.checked){
            const resultInternet = dataCategoria.filter( item => item.id === parseInt(e.target.id))
            setdatosFiltrados([
                ...datosFiltrados,
                ...resultInternet
            ])

            setAsistenciaExist(true)
            setCorreoExist(true)
            setIntExist(true)
            setTelExist(true)
    
        }else{

            //console.log(e.target.value)
            const resultInternet = datosFiltrados.filter( item => item.id !== parseInt(e.target.id))

            //console.log(resultInternet)
            setdatosFiltrados([
                ...resultInternet
            ])

            setAsistenciaExist(true)
            setCorreoExist(true)
            setIntExist(true)
            setTelExist(true)
        }

    }   

    const cargarAyuda = (titulo, cuerpo) => {


        settituloAyuda(titulo)
        setCuerpoAyuda(cuerpo)
        
    }

    const actualizarProceso = () =>{
        
            setdatosFiltrados([]);
            
            setCheckboxState(new Array(4).fill(false))

            setcheckboxStateSub(new Array(dataCategoria.length).fill(false))

            setCatInternet(false)
            setCatAsistencia(false)
            setCatCorreo(false)
            setCatTelefonia(false)
            setinformacionExtra(false)

            setMostraExtra(false)

            setbtnExtra(false)
            setbtnInfo(false)
            setcheckGuardar(false)

            setcantEquipos(1);
            setcantUsuarios(1);

            setbtnExtra(false)
            setbtnInfo(false)
            setbtnCorreo(false)
            setinformacionExtra(false)
        

            setAsistenciaExist(true)
            setCorreoExist(true)
            setIntExist(true)
            setTelExist(true)

            setIsCheckedReclamo(false)
            setIsCheckedServicio(false)
            setIsCheckedEmpresa(false)

            setIsCheckedReclamo(false)
            setIsCheckedServicio(false)
            setIsCheckedEmpresa(false)
            setValorNroReclamo('')
            setNroServicio('')

            setInputs([])
            setcantEquipos(1)
            setcantUsuarios(1)

            setCasoInternet('')
            setCasoAsistencia('')
            setCasoCorreo('')
            setCasoTelefonia('')
            
            setControlGuardar(false)
            setMostrarInternet(false)
            setMostraraAsistencia(false)
            setMostrarCorreo(false)
            setMostrarTelefonia(false)
            

            cargarAyuda('Categorías', 'Seleccione la opción que corresponde a su pedido.') 
        
    }

    const comprobarOp = () => {

        //console.log('comprobarOp')

        let categorias = [
            { nombre: "correo", activa: catCorreo },
            { nombre: "internet", activa: catInternet },
            { nombre: "telefonía", activa: catTelefonia },
            { nombre: "asistencia", activa: catAsistencia }
        ];
        
        let categoriasActivas = categorias.filter(categoria => categoria.activa).map(categoria => categoria.nombre);
        
        const verificarExistencia = (categoria, setExistFunc) => {

            //console.log(categoria)
         
            let cuenta = 0;
        
            if (datosFiltrados.length > 0) {
                datosFiltrados.forEach(element => {
                    if (element.categoria === categoria) {
                        cuenta++;
                    }
                });

                if (cuenta <= 0) {
                    setExistFunc(false);
                }
            } else {
                setExistFunc(false);
            }
        };
        
        let existencias = {
            internet: true,
            asistencia: true,
            correo: true,
            telefonia: true
        };
        
        categoriasActivas.forEach(categoria => {
            switch (categoria) {
                case 'internet':
                    verificarExistencia('internet', setIntExist);
                    break;
                case 'asistencia':
                    verificarExistencia('asistencia', setAsistenciaExist);
                    break;
                case 'correo':
                    verificarExistencia('correo', setCorreoExist);
                    break;
                case 'telefonía':
                    verificarExistencia('telefonia', setTelExist);
                    break;
            }
        });
        
        categoriasActivas.forEach(categoria => {
            switch (categoria) {
                case 'internet':
                    if(!openInternet){
                       verificarExistencia('internet', (valor) => existencias.internet = valor);
                    }
                    break;
                case 'asistencia':
                    if(!openAsistencia){
                        verificarExistencia('asistencia', (valor) => existencias.asistencia = valor);
                    }
                    break;
                case 'correo':
                    if(!openCorreo){
                      verificarExistencia('correo', (valor) => existencias.correo = valor);
                    }
                    break;
                case 'telefonía':
                    if(!openTelefonia){
                      verificarExistencia('telefonia', (valor) => existencias.telefonia = valor);
                    }
                    break;
            }
        });
        

        if (Object.values(existencias).every(valor => valor)) {

            //console.log("-------------------------------------------------------------");
            //console.log('Comprobación de selección verdaderas pa');

            infoExtra()
        }

    }

    const handleInputChange = (event, index, campo) => {
        const nuevasPersonas = [...personas];
        let persona = nuevasPersonas[index] || {};
        persona = {
          ...persona,
          [campo]: event.target.value
        };
        nuevasPersonas[index] = persona;
        setPersonas(nuevasPersonas);
    }

    const handleReclamo = (event) => {
      setIsCheckedReclamo(event.target.checked);
    };

    const handleServicio = (event) => {
        setIsCheckedServicio(event.target.checked);
    };
  
    const handleEmpresa = (event) => {
        setIsCheckedEmpresa(event.target.checked);
     };

    const infoExtra = () => {
    
        //('infoExtra')

        let estado = datosFiltrados.filter( item => item.id === 3);
        //console.log(datosFiltrados)

        let categorias = [
            { nombre: "Correo", activa: !openCorreo ? catCorreo : setCatCorreo(false) },
            { nombre: "Internet", activa: !openInternet ? catInternet : setCatInternet(false) },
            { nombre: "Telefonía", activa: !openTelefonia ? catTelefonia : setCatTelefonia(false) },
            { nombre: "Asistencia", activa: !openAsistencia ? catAsistencia : setCatAsistencia(false)}
        ];

        let categoriasActivas = categorias.filter(categoria => categoria.activa).map(categoria => categoria.nombre);

        if (categoriasActivas.length === 0) {

            console.log("Ninguna categoría está activa.");


        } else if (categoriasActivas.length >= 1) {

            //console.log(`Solo ${categoriasActivas[0]} está activa.`);
             
            
                 
              if (catTelefonia) {

              
                    if(!catAsistencia && !catCorreo && !catInternet){
                         setMostraExtra(false)
                        //setinformacionExtra(true)
                        setbtnExtra(true)
                        setcheckGuardar(true)
                        cargarAyuda('Información', 'Ingrese la información solicitada si la conoce y presione Guardar.');
                        //console.log(catTelefonia)
                    }

                

                }
                if (catInternet) {

                    datosFiltrados.forEach(element => {
                        //console.log(element.id)
                        if(element.id===2){
                            setMostraExtra(true)
                            //setinformacionExtra(true)
                            setbtnExtra(true)
                            setcheckGuardar(true)
                            cargarAyuda('Información', 'Ingrese la información solicitada si la conoce y presione Guardar.');
                        }else{
                            setMostraExtra(true)
                            setinformacionExtra(true)
                            setbtnExtra(true)
                            setbtnInfo(true) 
                            cargarAyuda('Información', 'Ingrese la información solicitada si la conoce y presione Continuar.');
                           
                        }
                    });

                }
                if (catAsistencia) {

                    setMostraExtra(true)
                    setbtnExtra(true);
                    setbtnInfo(true);
                    setcheckGuardar(false)
                    setinformacionExtra(true)
                    cargarAyuda('Información', 'Ingrese la información solicitada si la conoce y presione Continuar.');
                }

                if (catCorreo) {

                    setMostraExtra(true)
                    setbtnExtra(true);
                    setbtnInfo(false);
                    setbtnCorreo(true)
                    setinformacionExtra(true)
                    cargarAyuda('Información', 'Ingrese la información solicitada si la conoce y presione Continuar.');
                }


        } 
       

     }


    const manejarNroReclamo = (e) => {
      setValorNroReclamo(e.target.value);
    };

    
    const manejarNroServicio = (e) => {
      setNroServicio(e.target.value);
    };

    const comprobarExtra = () =>{

        setbtnInfo(false)
        setcheckGuardar(true)
        cargarAyuda('Información', 'Ingrese la información solicitada si la conoce y presione Guardar.');

    }

    const procesarData = () => {

        //console.log(escuelaCaso[0])

        try{
        const nombreEscuela = escuelaCaso[0].cue.nombre
        const idescuela = escuelaCaso[0].id
        const nivelEscuela = escuelaCaso[0].nivel.nombre
        const codEmpresaEscuela = escuelaCaso[0].codigo_empresa
        const cueEscuela = escuelaCaso[0].cue.cue

        const mailAlt = document.getElementById('txt-mailAlt')
        const nombreContacto = contacto.contacto.nombre_persona
        const telContacto = contacto.contacto.contacto
        const cargoContacto = contacto.contacto.cargo
    
        const deptoEscuela = escuelaCaso[0]?.ubicacion?.departamento?.nombre ? escuelaCaso[0].ubicacion.departamento.nombre : '';
        const localidadEscuela = escuelaCaso[0]?.ubicacion?.localidad?.nombre ? escuelaCaso[0].ubicacion.localidad.nombre : '';
        const direccionEscuela = escuelaCaso[0]?.ubicacion?.direccion ? escuelaCaso[0].ubicacion.direccion : '';
        
        //const tipoReq //= //document.getElementById('selectTipo')
    
        //console.log(tipoReq[tipoReq.selectedIndex].value)
        return {
            id_escuela: idescuela,
            cue: cueEscuela,
            nombreEscuela: nombreEscuela,
            nivelEscuela: nivelEscuela,
            codEmpresa: codEmpresaEscuela,
            localidadEscuela: `${localidadEscuela} - ${deptoEscuela}`,
            direccionEscuela: direccionEscuela,
            mailEscuela: `${codEmpresaEscuela}@me.cba.gov.ar` ,
            mailAltEscuela: mailAlt.value != '' ? mailAlt.value : '' ,
            nombreContacto: nombreContacto ? nombreContacto : '',                              
            cargoContacto: cargoContacto ? cargoContacto : '',
            celContacto: telContacto ? telContacto : '',
            //idReq: tipoReq[tipoReq.selectedIndex].value,
            //idReq: 'otro',
            //tipoReq: tipoReq[tipoReq.selectedIndex].text,
            //textCorreo: textTicket.value,
            telContacto: '',
            'Otro-nombreContacto': '',
            'Otro-cargoContacto': '',
            'Otro-celContacto': ''
        }
    
        } catch (e) {
        let mensaje = "Ocurrio un error al leer los datos: "+e;
        console.log(e)
        
        }
    }

    const handleDetalleChange = (e, setDetalle) => {
        if (e.target.value.length <= maxCaracteres) {
            setDetalle(e.target.value);
        }
    }
  
    const generarInputs = () => {

    if(cantUsuarios > 0){    
    setbtnCorreo(false)    
    setbtnInfo(true)
    

    const nuevosInputs = [];

    for (let i = 0; i < cantUsuarios; i++) {
        nuevosInputs.push(<input key={i} type="text" name="nombre" />);
    }
    setInputs(nuevosInputs);

    }else{
        console.log('es cero titan e')
    }


    }

    const guardarTkt = async() =>{

       
        //console.log(data)

        // INTERNET              1 *
        // CORREO INSTITUCIONAL  2 *
        // SOLICITUD DESBLOQUEOS 3
        // SOPORTE TECNICO       4 *
        // ENTREGA EQUIPAMIENTO  5 
        // TELEFONIA             6 *

        setcheckGuardar(false)
        setinformacionExtra(false)

        if(catInternet == true){
            
            let data = procesarData()

            data.textCorreo = "Estimadas/os, <br> La escuela con el fín de obtener solución selecciona que: <br> "

            datosFiltrados.forEach(element => {    

                if(element.categoria == 'internet'){
                    
                    data.textCorreo += " "+element.descripcion+"<br> "

                    if(element.id === 1){                 
                        {isCheckedReclamo ? data.textCorreo += 'Se realizó el reclamo al 0800 444 1115. <br>' : data.textCorreo +='No se realizó el reclamo al 0800 444 1115. <br>'}
                        {valorNroReclamo !== '' ? data.textCorreo +='El nro de reclamo es: '+valorNroReclamo+'<br>' : data.textCorreo +='No proporciona numero de reclamo<br>'}
                    }
                    if(element.id === 3){ 
                        {isCheckedServicio ? data.textCorreo +='¿Tenía servicio y ya no funciona? Si<br>'  : data.textCorreo +='¿Tenía servicio y ya no funciona? No<br>'}
                        {isCheckedEmpresa ? data.textCorreo +='¿Se contactó Con el Empresa que brinda el servicio? Si<br>' : data.textCorreo +='¿Se contactó Con el Empresa que brinda el servicio? No<br>'}
                        {valorNroServicio !== '' ? data.textCorreo +='El nro de reclamo es: '+valorNroServicio+'<br>' : data.textCorreo +='No proporciona numero de reclamo<br>'}
            
                }
            }})
           
            {detalleInternet !== '' ? data.textCorreo +="La persona que se comunicó también menciona que: "+detalleInternet+"<br>" : data.textCorreo +=''}
            data.usuario = User[0].username   

            let descripcion = await Template(data).props.children[1]
            //console.log(descripcion)

            let idEscuela =  (data.id_escuela).toString()
            
            //console.log("guardamos?")        
       
            let url = URL_ASISTENCIAS+'tickets'
 
            await fetch(url , {
                method: 'POST',
                //mode: 'no-cors', // no-cors, *cors, same-origin
                headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(
                    {
                    "titulo": data.cue +' - INTERNET',
                    "descripcion":  Template(data).props.children[1],
                    "id_escuela": idEscuela,
                    "id_categoria": "1",
                    "usuario": data.usuario,
                    "org_id" : "2"
                    }
                
                ),
            })
            .then(response => response.json())
            .then(response =>{
                if(response.resultado == true){
                    setCasoInternet(response.data.nro_ticket)
                    setControlGuardar(true)

                    if(catInternet){
                        setMostrarInternet(true)
                    }
                    if(catAsistencia){
                        setMostraraAsistencia(true)
                    }
                    if(catCorreo){
                        setMostrarCorreo(true)
                    }
                    if(catTelefonia){
                        setMostrarTelefonia(true)
                    }
                }

            }    

            )
            .catch(err => console.error(err));

    
        }

        if(catAsistencia == true){
            
            let data = procesarData()

            data.textCorreo = "Estimadas/os, <br> La escuela con el fín de obtener solución selecciona que: <br> "

            datosFiltrados.forEach(element => {    

                //console.log(element.categoria)

                if(element.categoria == 'asistencia'){                    
                    data.textCorreo += " "+element.descripcion+"<br> "              

                }})

            data.textCorreo += "Cantidad de equipos: "+cantEquipos+"<br> "
            {detalleAsistencia !== '' ? data.textCorreo +="La persona que se comunicó también menciona que: "+detalleAsistencia+"<br>" : data.textCorreo +=''}
            data.usuario = User[0].username   

            let descripcion = await Template(data).props.children[1]
            //console.log(descripcion)

            let idEscuela =  (data.id_escuela).toString()
       
            let url = URL_ASISTENCIAS+'tickets'
    
            await fetch(url , {
                method: 'POST',
                //mode: 'no-cors', // no-cors, *cors, same-origin
                headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(
                    {
                    "titulo": data.cue +' - SOPORTE TÉCNICO',
                    "descripcion":  Template(data).props.children[1],
                    "id_escuela": idEscuela,
                    "id_categoria": "4",
                    "usuario": data.usuario,
                    "org_id" : "2"
                    }
                
                ),
            })
            .then(response => response.json())
            .then(response =>{
                if(response.resultado == true){
                    setCasoAsistencia(response.data.nro_ticket)
                    setControlGuardar(true)
                }

            }    

            )
            .catch(err => console.error(err));

       

        }
 
        if(catCorreo == true){

            let data = procesarData()
            data.textCorreo = "Estimadas/os, <br> La escuela con el fín de obtener solución selecciona que: <br> "

            datosFiltrados.forEach(element => {    

                //console.log(element.categoria)

                if(element.categoria == 'correo'){
                    data.textCorreo += " "+element.descripcion+"<br> "                    
                }})
              

                {detalleCorreo !== '' ? data.textCorreo +="La persona que se comunicó también menciona que: "+detalleCorreo+"<br>" : data.textCorreo +=''}
                data.textCorreo += "Cantidad de cuentas: "+cantUsuarios+";<br> "

                if (personas) {

                    data.textCorreo += `
                      <table "table table-striped">
                     
                          <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>CUIL</th>
                            <th>Cuenta</th>
                          </tr>
                
                   
                          ${personas.map((element, index) => `
                            <tr>
                              <th>${index + 1}</th>
                              <td>${element.nombre}</td>
                              <td>${element.cuil}</td>
                              <td>${element.cuenta}@me.cba.gov.ar</td>
                            </tr>
                          `).join('')}
                   
                      </table>
                    `;
                  }
            
            data.usuario = User[0].username   

            let descripcion = await Template(data).props.children[1]
            //console.log(descripcion)

            let idEscuela =  (data.id_escuela).toString()
            
            //console.log("guardamos?")        
   
       
            let url = URL_ASISTENCIAS+'tickets'
    
            await fetch(url , {
                method: 'POST',
                //mode: 'no-cors', // no-cors, *cors, same-origin
                headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(
                    {
                    "titulo": data.cue +' - CORREO INSTITUCIONAL',
                    "descripcion":  Template(data).props.children[1],
                    "id_escuela": idEscuela,
                    "id_categoria": "2",
                    "usuario": data.usuario,
                    "org_id" : "2"
                    }
                
                ),
            })
            .then(response => response.json())
            .then(response => {                     
                
                if(response.resultado == true){
                    setCasoCorreo(response.data.nro_ticket)
                    setControlGuardar(true)
                }

                }
            )
            .catch(err => console.error(err));
        }

        if(catTelefonia == true){
            
            let data = procesarData()

            data.textCorreo = "Estimadas/os, <br> La escuela con el fín de obtener solución selecciona que: <br> "

            datosFiltrados.forEach(element => {    

                //element.categoria)

                if(element.categoria == 'telefonia'){        
                    data.textCorreo += " "+element.descripcion+";<br> "  
                }})


            
            {detalleTelefonia !== '' ? data.textCorreo +="La persona que se comunicó también menciona que: "+detalleTelefonia+"<br>" : data.textCorreo +=''}
            data.usuario = User[0].username   

            let descripcion = await Template(data).props.children[1]
            //console.log(descripcion)

            let idEscuela =  (data.id_escuela).toString()
            
            //console.log("guardamos?")        
          
            let url = URL_ASISTENCIAS+'tickets'
    
            await fetch(url , {
                method: 'POST',
                //mode: 'no-cors', // no-cors, *cors, same-origin
                headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(
                    {
                    "titulo": data.cue +' - TELEFONÍA',
                    "descripcion":  Template(data).props.children[1],
                    "id_escuela": idEscuela,
                    "id_categoria": "6",
                    "usuario": data.usuario,
                    "org_id" : "2"
                    }
                
                ),
            })
            .then(response => response.json())
            .then(response =>{
                    if(response.resultado == true){
                        setCasoTelefonia(response.data.nro_ticket)
                        setControlGuardar(true)
                    }

                }              
              )
            .catch(err => console.error(err));

            
        }
  

    }


  return(
    
        <div>

            <div className={controlGuardar === false ? 'row' : 'd-none'} >

                <div className='col-12 mb-4 '>

                    <div className= {mostraExtra === true ? 'row  mt-4 mx-4 disabled' : 'row  mt-4 mx-4 '}>
                  
                        <div className='col-12 col-md-3 mt-4 '>
                            <div className='d-grid gap-2'>
                             <input type="checkbox" className="btn-check" onChange={selCategoria} name ="0" checked={checkboxState[0]} id="internet"   />
                             <label className="btn btn-outline-secondary mb-2 text-center hstack d-flex justify-content-center" htmlFor="internet">  INTERNET   </label>
                             </div>
                            <div className={catInternet === true && openInternet === true?'mt-2': 'd-none' }id="opInternet">
                            <div className="card">
                                     <div className="card-body">
                                         <p className="card-text text-center">Estamos trabajando en una asistencia con esta categoria.</p>                                        
                               
                                            {casosInternet.map((nro_ticket)=>{
                                                return(
                                                    <div className="input-group mb-3 hstack gap-2 d-flex justify-content-center">
                                                    <label htmlFor="button-addon1">Nro: <strong>{nro_ticket}</strong></label>
                                                    <a href={"https://mesadeayuda.educacioncba.edu.ar/mostrar/"+nro_ticket}  className="btn btn-outline-success" type="button" id="button-addon1">Ver</a>
                                                     </div>

                                                )
                                                })}                                                               
                                     </div>
                                 </div>

                            </div>

                            <div className={catInternet === true && openInternet === false? ' mt-2' : ' mt-2  d-none'  } id="opInternet">
                 
                            <div className="form-check mb-2"       >
                                        <input 
                                          
                                            className= {intExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="radio"
                                            checked={checkboxStateSub[2]} 
                                            name = "internet"
                                            id="3"
                                            value = 'La escuela no tiene internet'
                                            onChange={handleRadioChange}
                                            
                                        />

                                        <label className="form-check-label" htmlFor="3">
                                            La escuela no tiene internet
                                        </label>


                                     
                                     </div>
                                    <div className="form-check mb-2 " >
                                        <input 
                                            
                                            className= {intExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="radio"
                                            checked={checkboxStateSub[0]} 
                                            name = "internet"                    
                                            id= "1"
                                            value = 'MAESTROS/ESTUDIANTES'
                                            onChange={handleRadioChange}    
                                            />
                                        <label 
                                            className="form-check-label" 
                                            htmlFor="1"
                                            >Sin internet: MAESTROS/ESTUDIANTES
                                        </label>
                                    </div>
                                    <div className="form-check mb-2"  >
                                        <input 
                                         
                                            className= {intExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="radio"
                                            checked={checkboxStateSub[1]}
                                            name = "internet" 
                                            id="2" 
                                            value = 'CONECTIVIDAD CÓRDOBA'
                                            onChange={handleRadioChange}  
                                                                          
                                        />
                                        <label className="form-check-label" htmlFor="2">
                                            Sin internet: CONECTIVIDAD CÓRDOBA
                                        </label>
                                        <div className="invalid-feedback">
                                            <div className='row hstack gap-1'>
                                                <div className='col-1'>
                                                    <span class="material-symbols-outlined">
                                                            dangerous
                                                    </span>
                                                </div>
                                                <div className='col-10'>
                                                   Debe seleccionar una opción ó presionar sobre "INTERNET".
                                                </div>
                                        </div>
                                       </div>
                                    </div>
                                    
                                            

                            </div>



                        </div>


                        <div className='col-12 col-md-3 mt-4'>
                        <div className='d-grid gap-2'>
                            <input type="checkbox" className="btn-check" onChange={selCategoria} name ="1" checked={checkboxState[1]} id="asistencia"  />
                            <label className="btn btn-outline-secondary mb-2" htmlFor="asistencia">ASISTENCIA TÉCNICA</label>

                            </div>
                            <div className={catAsistencia === true && openAsistencia === true?'mt-2': 'd-none' }id="opAsistencia">
                             
                            <div className="card">
                                     <div className="card-body">
                                         <p className="card-text text-center">Estamos trabajando en una asistencia con esta categoria.</p>     
                                                              
                                            {casosAsistencia.map((nro_ticket)=>{
                                                return(
                                                    <div className="input-group mb-3 hstack gap-2 d-flex justify-content-center">
                                                    <label htmlFor="button-addon1">Nro: <strong>{nro_ticket}</strong></label>
                                                    <a href={"https://mesadeayuda.educacioncba.edu.ar/mostrar/"+nro_ticket}  className="btn btn-outline-success" type="button" id="button-addon1">Ver</a>
                                                     </div>
                                                )
                                                })}                                                               
                                     </div>
                                 </div>

                            </div>
                            <div className={catAsistencia === true && openAsistencia === false? 'mt-1' : ' mt-1 d-none'} id="opAsistencia">

                                    <div className="form-check mb-2">
                                        <input 
                                           className= {asistenciaExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="checkbox"
                                            checked={checkboxStateSub[3]} 
                                            name = "asistencia" 
                                            id="4"
                                            value = 'Instalación de Office (ej.word)'
                                            onChange={controlChange}      
                                        />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Instalación de Office (ej. word)
                                        </label>
                                        </div>
                                        <div className="form-check mb-2">
                                        <input 
                                           className= {asistenciaExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="checkbox"
                                            checked={checkboxStateSub[4]} 
                                            name = "asistencia"
                                            id="5"
                                            value = 'Desbloqueo de netbooks'
                                            onChange={controlChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Desbloqueo de netbooks
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input 
                                           className= {asistenciaExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="checkbox"
                                            checked={checkboxStateSub[5]} 
                                            name = "asistencia"
                                            id="6"
                                            value = 'Equipos con desperfectos'
                                            onChange={controlChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Equipos con desperfectos
                                        </label>
                                   
                                        <div className="invalid-feedback">
                                            <div className='row hstack gap-1'>
                                                <div className='col-1'>
                                                    <span class="material-symbols-outlined">
                                                            dangerous
                                                    </span>
                                                </div>
                                                <div className='col-10'>
                                                   Debe seleccionar una opción ó presionar sobre "ASISTENCIA TÉCNICA".
                                                </div>
                                        </div>
                                      
                                           
                                    
                                        </div>                                            
                                        </div>
                                       
                                    </div>
                            </div>

                        <div className='col-12 col-md-3 mt-4  '>
                        <div className='d-grid gap-2'>
                            <input type="checkbox" className="btn-check" onChange={selCategoria} name ="2" checked={checkboxState[2]} id="correo"  />
                            <label className="btn btn-outline-secondary mb-2 hstack d-flex justify-content-center" htmlFor="correo">CORREO INSTITUCIONAL</label>
                            </div>
                            <div className={catCorreo === true && openCorreo === true?'mt-2': 'd-none' }id="opCorreo">

                            <div className="card">
                                     <div className="card-body">
                                     <p className="card-text text-center">Estamos trabajando en una asistencia con esta categoria.</p>     
                                                                       
                                            {casosCorreo.map((nro_ticket)=>{
                                                return(
                                                    <div className="input-group mb-3 hstack gap-2 d-flex justify-content-center">
                                                    <label htmlFor="button-addon1">Nro: <strong>{nro_ticket}</strong></label>
                                                    <a href={"https://mesadeayuda.educacioncba.edu.ar/mostrar/"+nro_ticket}  className="btn btn-outline-success" type="button" id="button-addon1">Ver</a>
                                                     </div>

                                                )
                                                })}                                                               
                                     </div>
                                 </div>


                            </div>
                            <div className={catCorreo === true && openCorreo === false?'mt-1' : 'col-12 col-md-3 mt-1 border-start border-end d-none'} id="opCorreo">
                                        <div className="form-check mb-2">
                                        <input 
                                           className= {correoExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="checkbox"
                                            checked={checkboxStateSub[7]} 
                                            name = "correo"
                                            id="8"
                                            value = 'Nuevo/s usuarios'
                                            onChange={controlChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Nuevo/s usuarios
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input 
                                           className= {correoExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="checkbox"
                                            checked={checkboxStateSub[8]} 
                                            name = "correo"
                                            id="9"
                                            value = 'Modificar información de una cuenta'
                                            onChange={controlChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Modificar información de cuentas
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input 
                                           className= {correoExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                            type="checkbox"
                                            checked={checkboxStateSub[13]} 
                                            name = "correo"
                                            id="14"
                                            value = 'Modificar información de una cuenta'
                                            onChange={controlChange}
                                        />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                              Modificar datos de contacto (teléfono/email alternativo)
                                        </label>
                                        <div className="invalid-feedback">
                                            <div className='row hstack gap-1'>
                                                <div className='col-1'>
                                                    <span class="material-symbols-outlined">
                                                            dangerous
                                                    </span>
                                                </div>
                                                <div className='col-10'>
                                                   Debe seleccionar una opción ó presionar sobre "CORREO INSTITUCIONAL".
                                                </div>
                                        </div>
                                       </div>
                                    </div>
                                </div>
                        </div>

                        <div className='col-12 col-md-3 mt-4  '>
                        <div className='d-grid gap-2'>
                            <input type="checkbox" className="btn-check" onChange={selCategoria} name ="3" checked={checkboxState[3]} id="telefonia"  />
                            <label className="btn btn-outline-secondary mb-2 hstack d-flex justify-content-center" htmlFor="telefonia">TELEFONÍA</label>
                        </div>
                        <div className={catTelefonia === true && openTelefonia === true?'mt-2': 'd-none' }id="opTelefonia">
                        <div className="card">
                                     <div className="card-body">
                                     <p className="card-text text-center">Estamos trabajando en una asistencia con esta categoria.</p>     
                                                                     
                                            {casosTelefonia.map((nro_ticket)=>{
                                                return(

                                                    <div className="input-group mb-3 hstack gap-2 d-flex justify-content-center">
                                                    <label htmlFor="button-addon1">Nro: <strong>{nro_ticket}</strong></label>
                                                    <a href={"https://mesadeayuda.educacioncba.edu.ar/mostrar/"+nro_ticket} className="btn btn-outline-success" type="button" id="button-addon1">Ver</a>
                                                     </div>
                                                )
                                                })}                                                               
                                     </div>
                                 </div>

                            </div>
                             <div className={catTelefonia === true && openTelefonia === false? ' mt-1 ' :  'mt-1 d-none'}  id="opTelefonia">

                                <div className="form-check mb-2">
                                    <input 
                                        className= {telExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                        type="checkbox"
                                        checked={checkboxStateSub[9]} 
                                        name = "telefonia"
                                        id="10"
                                        value = 'No hay tono'
                                        onChange={controlChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        No hay tono
                                    </label>
                                    </div>
                                    <div className="form-check mb-2">
                                    <input 
                                        className= {telExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                        type="checkbox"
                                        checked={checkboxStateSub[10]} 
                                        name = "telefonia"
                                        id="11"
                                        value = 'Habilitar llamadas a celulares'
                                        onChange={controlChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        Habilitar llamadas a celulares
                                    </label>
                                    </div>
                                    <div className="form-check mb-2">
                                    <input 
                                        className= {telExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                        type="checkbox"
                                        checked={checkboxStateSub[11]} 
                                        name = "telefonia"
                                        id="12"
                                        value = 'No hay servicio'
                                        onChange={controlChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        No hay servicio
                                    </label>
                                    </div>
                                    <div className="form-check mb-2">
                                    <input 
                                        className= {telExist === false ? 'form-check-input is-invalid' : 'form-check-input '}
                                        type="checkbox"
                                        checked={checkboxStateSub[12]} 
                                        name = "telefonia"
                                        id="13"
                                        value = 'Traslado de linea'
                                        onChange={controlChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Traslado de línea
                                    </label>
                                    <div className="invalid-feedback">
                                            <div className='row hstack gap-1'>
                                                <div className='col-1'>
                                                    <span class="material-symbols-outlined">
                                                            dangerous
                                                    </span>
                                                </div>
                                                <div className='col-10'>
                                                   Debe seleccionar una opción ó presionar sobre "TELEFONÍA".
                                                </div>
                                        </div>
                                       </div>
                                    </div>
                            </div>
                        </div>
                     </div>

                    <div className= {informacionExtra === true ?  checkGuardar === false ?  'mt-4 ' : 'mt-4 disabled' : 'd-none' } id="contInfo">
                        <div className='col-12'>
                        <div className='card '>
                        <div className="card-header bg-transparent">
                                <div className="hstack gap-1">
                                    <span className="material-symbols-outlined celeste">assignment_late</span>Información
                                </div>
                            </div>
                            <div className="card-body">

                                <div className='row'> 

                                    <div className={catInternet === true ? 'col-12 col-md-8' : 'd-none'}>
                                                                                                      {
                                                                datosFiltrados.map(element => {                                                                      
        
                                                                    if(element.id === 1){
                                                                        return(
                                                                            <div>
                                                                                <h5 className='mb-3'>Internet</h5>

                                                                                <div className='row mb-2'>                                           
                                                                                    <div className='col-8 col-md-7 d-flex justify-content-end'>
                                                                                        ¿Realizo el Reclamo al 0800 444 1115?
                                                                                    </div>
                                                                                    <div className='col-10 col-md-2 hstack gap-2'>
                                                                                    <span>{isCheckedReclamo ? 'Sí' : 'No'}</span>
                                                                                        <label className="switch">
                                                                                            <input type="checkbox" checked={isCheckedReclamo} onChange={handleReclamo} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>
                                                                                        
                                                                                    </div>          
                                                                                </div>
                                        
                                                                                <div className={isCheckedReclamo ? 'row ' : 'd-none'} >                                           
                                                                                    <div className='col-6 col-md-7 d-flex justify-content-end mt-2'>
                                                                                    Ingrese Número de Incidente 
                                                                                    </div>
                                                                                    <div className='col-10 col-md-5'>
                                                                                
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text" id="basic-addon1">N°</span>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder=""
                                                                                            aria-label="nroReclamo"
                                                                                            aria-describedby="basic-addon1"
                                                                                            value={valorNroReclamo}
                                                                                            onChange={manejarNroReclamo}
                                                                                        />
                                                                                    </div>
                                                                                    </div>             
                                                                                </div>
                                                                            </div> 
                                                                        )  
                                                                    }

                                                                    if(element.categoria === 'internet'){
                                                                        if(element.id === 3){
                                                                            return(
                                                                            <div>
                                                                             <h5 className='mb-3'>Internet</h5>
                                                                              <div className='row mb-2'>                                           
                                                                                    <div className='col-7 col-md-7 d-flex justify-content-end'>
                                                                                    ¿Tenía servicio y ya no funciona?
                                                                                        </div>
                                                                                    <div className='col-4 col-md-2 hstack gap-2'>
                                                                                        <span>{isCheckedServicio ? 'Sí' : 'No'}</span>
                                                                                        <label className="switch">
                                                                                            <input type="checkbox" checked={isCheckedServicio} onChange={handleServicio} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>
                                                                                    </div>        
                                                                                </div>

                                                                                <div className={isCheckedServicio ? 'row mb-2' : 'd-none'}>                                           
                                                                                    <div className='col-7 d-flex justify-content-end'>
                                                                                    ¿Se contactó Con el Empresa que brinda el servicio?
                                                                                        </div>
                                                                                        <div className='col-4 hstack gap-2'>
                                                                                        <span>{isCheckedEmpresa? 'Sí' : 'No'}</span>
                                                                                        <label className="switch">
                                                                                            <input type="checkbox" checked={isCheckedEmpresa} onChange={handleEmpresa} />
                                                                                            <span className="slider round"></span>
                                                                                        </label>
                                                                                    </div>           
                                                                                </div>

                                                                                <div className={isCheckedEmpresa ? 'row mb-2' : 'd-none'} >                                           
                                                                                    <div className='col-6 col-md-7 d-flex justify-content-end mt-2'>
                                                                                    Ingrese Numero de Reclamo 
                                                                                    </div>
                                                                                    <div className='col-10 col-md-5'>
                                                                                    
                                                                                        <div className="input-group ">
                                                                                            <span className="input-group-text" id="basic-addon1">N°</span>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                placeholder=""
                                                                                                aria-label="nroReclamo"
                                                                                                aria-describedby="basic-addon1"
                                                                                                value={valorNroServicio}
                                                                                                onChange={manejarNroServicio}
                                                                                            />
                                                                                        </div>
                                                                                        </div>             
                                                                                </div>
                                                                            </div>)
                                                                        }

                                                                    }                                                                  

                                                                })                                        
                                                            }
                                       
                                    </div>

                                    <div className={catAsistencia === true ? 'col-12 col-md-8 p-3' : 'd-none'}>
                                    <h5 className='mb-3'>Asistencia Técnica</h5>
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon3">Cantidad</span>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="cantEquipos" 
                                                value={cantEquipos}
                                                onChange={e => setcantEquipos(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-text" id="basic-addon4">Ingrese la cantidad de equipos que tienen inconvenientes.</div>
                                    </div>

                                    <div className={catCorreo === true ? 'col-12 col-md-8 p-3' : 'd-none'}>

                                        <div className={cantUsuarios > 0 && btnInfo === true ? 'disabled' : ''}>
                                        <h5 className='mb-3'>Correo Institucional</h5>
                                    
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon3">Cantidad</span>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="basic-url" 
                                                value={cantUsuarios}
                                                onChange={e => setcantUsuarios(e.target.value)}
                                            />
                                        </div>

                                        </div>
                                        <div className="form-text" id="basic-addon4">Ingrese la cantidad de cuentas.</div>

                                            <div className='d-flex justify-content-end'>   

                                            </div>

                                            <div className= {cantUsuarios > 0 && btnInfo === true ? 'mt-4' : 'd-none' }>

                                              <h5 className='mb-3'>Cuentas</h5>
                                         
                                                {inputs.map((input, index) => (
                                                
                                                <div key={index}>

                                                    <label htmlFor="basic-url" className="form-label">{'Persona '+(index+1)}</label>
                                                    <div className="form-floating mb-3 ">
                                                    
                                                    <input type="text" id={'nombre'+index} onChange={(e) => handleInputChange(e, index, 'nombre')} className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1"/>
                                                    <label htmlFor={'nombre'+index} >Nombre/s y Apellido/s</label>
                                                    </div>
                                                    
                                                    <div className="form-floating mb-3">
                                                    
                                                    <input type="text" id={'cuil'+index} onChange={(e) => handleInputChange(e, index, 'cuil')} className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1"/>
                                                    <label  >CUIL</label>
                                                    </div>

                                                    <div className="form-floating mb-3">
                                                    <input type="text"  id={'cuenta'+index} onChange={(e) => handleInputChange(e, index, 'cuenta')} className="form-control" placeholder=" " aria-label=" " aria-describedby="basic-addon2"/>
                                                    <label >@me.cba.gov.ar</label>
                                                    </div>

                                                </div>

                                                ))}

                                               
                                            </div>
                                    </div>

                                </div>
                            </div>
                         </div>
                         </div>
                    </div>
                   
                   <div className= {checkGuardar === true ?  'mt-4' : 'd-none'}>
                       
                   <div className="card">

                   <div className="card-header ">
                                  <div className="hstack gap-1">
                                      <span className="material-symbols-outlined celeste">
                                      assignment_late
                                      </span>
                                      Detalle del inconveniente
                                  </div>   
                                </div>

                                    <div className="card-body ">
                                            <div className="hstack">
                                                <div className='col-12 mt-2 d-flex justify-content-start'>
                                                    <span className="material-symbols-outlined text-warning mx-1">
                                                        info
                                                    </span>
                                                    <p className='text-warning'>
                                                        Se generará un caso para cada categoría seleccionada previamente, el cual será asignado al área correspondiente.
                                                    </p> 
                                                </div>
                                            </div>

                                        <div className={catInternet === true ? 'col-12 ' : 'd-none'}>
                                            <div className="card">
                                                 
                                                    <div className="card-body">
                                                        <h5 className="card-title">Internet</h5>
                                                        <ul>
                                                            {datosFiltrados.map(element => {                                                                      
        
                                                                    if(element.categoria === 'internet'){
                                                                        return(
                                                                         <li key={element.id} >{element.descripcion}</li> 
                                                                        )
                                                                    }
                                                                
                                                                                                                               
                                                                })                                        
                                                            }
                                                        </ul>
                                                         

                                                        {datosFiltrados.map(element => {   
                                                                if(element.id === 1){                                                    
                                                                return(
                                                                <div>
                                                                <div className='mx-3'>{isCheckedReclamo ? 'Se realizó el reclamo al 0800 444 1115' : 'No se realizó el reclamo al 0800 444 1115'}</div>
                                                                <div className='mx-3 mb-3'>{valorNroReclamo !== '' ? 'El nro de reclamo es: '+valorNroReclamo : 'No proporciona numero de reclamo'}</div>
                                                                </div> 
                                                                )
                                                                }  

                                                                if(element.id === 3){
                                                                return(
                                                                <div>
                                                                    <div className='mx-3'>{isCheckedServicio ? '¿Tenía servicio y ya no funciona? Si'  : '¿Tenía servicio y ya no funciona? No'}</div>
                                                                    <div className='mx-3'>{isCheckedEmpresa ? '¿Se contactó Con el Empresa que brinda el servicio? Si' : '¿Se contactó Con el Empresa que brinda el servicio? No'}</div>
                                                                    <div className='mx-3 mb-3'>{valorNroServicio !== '' ? 'El nro de reclamo es: '+valorNroServicio : 'No proporciona numero de reclamo'}</div>
                                                                </div>
                                                                )
                                                                }
                                                           })}

                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text">Detalle </span>
                                                            <textarea
                                                            className="form-control"
                                                            aria-label="With textarea"
                                                            value={detalleInternet}
                                                            onChange={(e) => handleDetalleChange(e, setDetalleInternet)}
                                                            ></textarea>

                                                        </div>
                                                        <div>
                                                            {maxCaracteres - detalleInternet.length} caracteres restantes
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                        <div className={catAsistencia === true ? 'col-12 mt-2' : 'd-none'}>
                                                    <div className="card">
                                                        {/*<h5 className="card-header">Internet</h5>*/}
                                                        <div className="card-body">
                                                            <h5 className="card-title">Asistencia técnica</h5>
                                                            <ul>
                                                                {
                                                                    datosFiltrados.map(element => {
                                                                  
                                                                        return(
                                                                            element.categoria === 'asistencia' ? <li key={element.id}>{element.descripcion}</li> : ''
                                                                        )
                                                                            

                                                                    })                                        
                                                                }
                                                            </ul>
                                                            <div className='mx-3 mt-2 mb-4'>{'Cantidad de equipos: '+cantEquipos}</div>
                                                            <div className="input-group mb-3">
                                                                <span className="input-group-text">Detalle </span>
                                                                <textarea
                                                                className="form-control"
                                                                aria-label="With textarea"
                                                                value={detalleAsistencia}
                                                                onChange={(e) => handleDetalleChange(e, setDetalleAsistencia)}
                                                                ></textarea>
                                                            </div>
                                                            <div>
                                                               {maxCaracteres - detalleAsistencia.length} caracteres restantes
                                                             </div>
                                                        </div>
                                                </div>
                                        </div>

                                        <div className={catCorreo === true ? 'col-12 mt-2' : 'd-none'}>
                                                <div className="card">
                                                    {/*<h5 className="card-header">Internet</h5>*/}
                                                    <div className="card-body">
                                                        <h5 className="card-title">Correo Institucional</h5>
                                                        <div>
                                                            <ul>
                                                            {
                                                                datosFiltrados.map(element => {
                                                               
                                                                    return(
                                                                        element.categoria === 'correo' ? <li key={element.id} >{element.descripcion}</li> : ''
                                                                    )
                                                                        

                                                                })                                        
                                                            }
                                                            </ul>
                                                                    <div className='mt-2 mb-2'>{'Cantidad de cuentas: '+cantUsuarios}</div>

                                                                    {
                                                                <div>
                                                                    {personas? 'Información de cuentas:': ''}

                                                                    <table className="table">
                                                                    <thead>
                                                                    <tr>
                                                                        <th scope="col">#</th>
                                                                        <th scope="col">Nombres/Apellido</th>
                                                                        <th scope="col">CUIL</th>
                                                                        <th scope="col">Cuenta</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        { personas.map((element, index) => {
                                                                                        
                                                                                return(                                                                               
                                                                                <tr>
                                                                                <th scope="row">{index+1}</th>
                                                                                    <td>{element.nombre}</td>
                                                                                    <td>{element.cuil}</td>
                                                                                    <td>{element.cuenta}@me.cba.gov.ar </td>
                                                                                </tr>   
                                                                                )
                                                                            })  
                                                                        }
                                                                    </tbody>
                                                                    </table>                                  
                                                            </div>

                                                                    }
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text">Detalle </span>
                                                            <textarea
                                                            className="form-control"
                                                            aria-label="With textarea"
                                                            value={detalleCorreo}
                                                            onChange={(e) => handleDetalleChange(e, setDetalleCorreo)}
                                                            ></textarea>
                                                        </div>
                                                        <div>
                                                          {maxCaracteres - detalleCorreo.length} caracteres restantes
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>

                                        <div className={catTelefonia === true ? 'col-12 mt-2' : 'd-none'}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h5 className="card-title">Telefonía</h5>
                                                                <ul>
                                                                    {
                                                                        datosFiltrados.map(element => {
                                                                                      
                                                                            return(
                                                                                element.categoria === 'telefonia' ? <li key={element.id}>{element.descripcion}</li> : ''
                                                                            )
                                                                                

                                                                        })                                        
                                                                    }
                                                                </ul>
                                                                <div className="input-group mb-3">

                                                                    <span className="input-group-text">Detalle </span>
                                                                    
                                                                    <textarea
                                                                    className="form-control"
                                                                    aria-label="With textarea"
                                                                    value={detalleTelefonia}
                                                                    onChange={(e) => handleDetalleChange(e, setDetalleTelefonia)}
                                                                    ></textarea>
                                                                </div>

                                                                <div>
                                                               {maxCaracteres - detalleTelefonia.length} caracteres restantes
                                                             </div>
                                                            </div>
                                                    </div>
                                        </div>
                                     
                              </div>
                        </div>

            
                   </div>

                </div>


            </div>

            <div className={controlGuardar === true ? 'd-none' : 'row mt-4'} >
                       <div className='col-12'>
                           <div className= {catInternet || catAsistencia || catCorreo || catTelefonia === true ?  checkGuardar === true ? 'card callout-guardar' :  btnInfo === true ? 'card  callout-info' : 'card  callout-advertencia' : ' card callout'} >
                           
                                 <div className="card-body" id="bodyAyuda">
                                    <div className='row'>
                                        <h5 className="card-title hstack gap-1"><span className="material-symbols-outlined">help</span><div id="tituloAyuda">{tituloAyuda}</div></h5>
                                        <p className="card-text" id="cuerpoAyuda">{cuerpoAyuda}</p>
                                    </div>
                                <div className='row'>
                                        <div className={catInternet || catAsistencia || catCorreo || catTelefonia === true ? "d-grid gap-2 d-md-flex justify-content-md-center mt-3" : 'd-none'}>
                                            <button className="btn btn-outline-danger me-md-2" type="button" onClick={actualizarProceso}>Cancelar</button>
                                            
                                            <button className={btnExtra === true ? 'd-none' : "btn btn-outline-secondary"} type="button" onClick={comprobarOp}>Continuar</button>{/* comprobarOp-Continuar */}

                                            <button type="button" className={btnCorreo === true  ? "btn btn-outline-secondary" : 'd-none'} onClick={generarInputs}>Continuar</button>{/* generarInputs-Continuar */}
                                            
                                            <button className={btnInfo === true ? "btn btn-outline-secondary" : 'd-none' }  type="button" onClick={comprobarExtra}>Continuar</button> {/* comprobarExtra-Continuar */}
                                                                                    
                                            <button className={checkGuardar === true ? "btn btn-outline-success" : 'd-none' }  type="button" onClick={guardarTkt}>Guardar</button> {/* guardarTkt -Guardar */}
                                        </div>
                                </div>    
                            </div>

                           </div>
                        </div>
                    </div>

            <div className={controlGuardar === true ? 'row' : 'd-none'}>

                <div className={casoInternet !== '' || casoAsistencia !== '' || casoCorreo !== '' || casoTelefonia !== '' ? 'd-none' : 'row' }>
                    <div className="text-center mt-4 mb-4">
                    <h4 className="text-secondary mt-3 mb-3">Estamos procesando la información...</h4>
                        <div className="spinner-border text-secondary" role="status">
                                <span className="visually-hidden">Loading...</span>
                        </div> 
                    </div>                  
                </div>

                <div className= {casoInternet !== '' || casoAsistencia !== '' || casoCorreo !== '' || casoTelefonia !== '' ?  'row text-center mt-3': 'd-none' }>

                    <h5 className="text-secondary mt-3 mb-3">Pronto nos comunicaremos para continuar con la gestión</h5>
                    <h6 className="text-secondary mb-4">Puede realizar el seguimiento de la/s asistencia/s generada/s a continuación:</h6>

                        <div className={catInternet == false && casoInternet === '' ? 'd-none' : "col"} >                        
                            <div className="card">
                                <div className="card-body">
                                <h5 className="text-secondary mb-3">Internet</h5>
                                <h6>Asistencia nro: <strong>{casoInternet}</strong></h6>                                        
                                    <div className='mb-2 col-12 hstack gap-2 d-flex justify-content-center' key={casoInternet}>                
                                        <a href={"https://mesadeayuda.educacioncba.edu.ar/mostrar/"+casoInternet}  className="btn btn-outline-success">Ver</a>
                                    </div>
                                                                                  
                                </div>
                            </div>
                        </div>
                   
                            <div className={mostrarInternet === true && casoInternet === ''?  "text-center mt-4 mb-4" : 'd-none '}>
                                <h4 className="text-secondary mt-3 mb-3">Estamos procesando la información...</h4>
                                <div className="spinner-border text-secondary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                </div> 
                            </div>   

                        <div className={catAsistencia == false && casoAsistencia === '' ? 'd-none' : "col"} >                         
                            <div className= "card">
                                <div className="card-body">
                                <h5 className="text-secondary mb-3">Asistencia Técnica</h5>   
                                <h6>Asistencia nro: <strong>{casoAsistencia}</strong></h6>
                                    <div className='mb-2 col-12 hstack gap-2 d-flex justify-content-center' key={casoAsistencia}>                
                                        <a href={"https://mesadeayuda.educacioncba.edu.ar/mostrar/"+casoAsistencia}  className="btn btn-outline-success">Ver</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                   

                        <div className={mostrarAsistencia === true && catAsistencia === '' ?  "text-center mt-4 mb-4" : 'd-none '}>
                            <h4 className="text-secondary mt-3 mb-3">Estamos procesando la información...</h4>
                            <div className="spinner-border text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                            </div> 
                        </div>   

    
                        <div className={catCorreo == false && casoCorreo === '' ? 'd-none' : "col"} >    
                                <div className="card-body">
                                <h5 className="text-secondary mb-3">Correo Institucional</h5>   
                                <h6>Asistencia nro: <strong>{casoCorreo}</strong></h6>
                                    <div className='mb-2 col-12 hstack gap-2 d-flex justify-content-center' key={casoCorreo}>                
                                        <a href={"https://mesadeayuda.educacioncba.edu.ar/mostrar/"+casoCorreo}  className="btn btn-outline-success">Ver</a>
                                    </div>
                                
                                </div>
                            </div>

                        <div className={mostrarCorreo === true && casoCorreo === '' ?  "text-center mt-4 mb-4" : 'd-none ' }>
                            <h4 className="text-secondary mt-3 mb-3">Estamos procesando la información...</h4>
                            <div className="spinner-border text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                            </div> 
                        </div>   

                        <div className={catTelefonia == false && casoTelefonia === '' ? 'd-none' : "col"} >    
                        <div className= "card">
                                <div className="card-body">
                                <h5 className="text-secondary mb-3">Telefonía</h5>
                                <h6>Asistencia nro: <strong>{casoTelefonia}</strong></h6>   
                                <div className='mb-2 col-12 hstack gap-2 d-flex justify-content-center' key={casoTelefonia}>                
                                        <a href={"https://mesadeayuda.educacioncba.edu.ar/mostrar/"+casoTelefonia} className="btn btn-outline-success">Ver</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={casoTelefonia === true && casoTelefonia === '' ?  "text-center mt-4 mb-4" : 'd-none '}>
                            <h4 className="text-secondary mt-3 mb-3">Estamos procesando la información...</h4>
                            <div className="spinner-border text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                            </div> 
                        </div>   

                </div>
            </div>

        </div>
   
)

    }